import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  errorDetail: {},
  reportData: [
    {
      fieldNames: [],
      fieldLabels: [],
      data: [],
      total: 0
    }
  ],
  report: {
    name: '',
    description: '',
    entity: '',
    fields: [],
    filter: {
      filters: []
    },
    aggregations: []
  },
  reportableEntities: []
  // reportData: [
  //   {
  //     fieldNames: [''],
  //     fieldLabels: [''],
  //     data: [],
  //     total: 0
  //   }
  // ],
  //report: {
  //   name: '',
  //   description: '',
  //   entity: '',
  //   fields: [
  //     {
  //       name: '',
  //       label: '',
  //       type: '',
  //       concatenated: ['']
  //     }
  //   ],
  //   filter: {
  //     filters: [
  //       {
  //         field: '', // the field name
  //         label: '',
  //         operator: {
  //           operator: '', // =, !=, >, <, IN
  //           label: '' // Equal, Not Equal, Greater Than, Less Than, In
  //         },
  //         value: '',
  //         type: '' // field.type
  //       }
  //     ]
  //   },
  //   aggregations: []
  // },
  // reportableEntities: [
  //   {
  //    entity: {
  //      name: '',
  //      description: '',
  //      disabled: false
  //     },
  //    fields: [
  //       {
  //         name: '',
  //         label: '',
  //         type: '',
  //         concatenated: ['']
  //       }
  //     ]
  //   }
  // ]
};

const slice = createSlice({
  name: 'reportsBuilder',
  initialState,
  reducers: {
    setError(state, action) {
      const errorDescription = action.payload.response.data.error;
      state.errorDetail = errorDescription;
    },
    resetError(state) {
      state.errorDetail = {};
    },
    setReportData(state, action) {
      state.reportData = action.payload;
    },
    setReportableEntities(state, action) {
      state.reportableEntities = action.payload;
    },
    setRootEntity(state = initialState.report, action) {
      state.report.entity = action.payload;
      state.report.fields = [];
      state.report.filter.filters = [];
      state.report.name = '';
      state.report.description = '';
    },
    setReportId(state, action){
      state.report.id = action.payload;
    },
    setSelectedEntityFields(state, action) {
      state.report.fields = action.payload;
    },
    setFilters(state, action) {
      state.report.filter.filters = action.payload;
    },
    setReportName(state, action) {
      state.report.name = action.payload;
    },
    setReportDescription(state, action) {
      state.report.description = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setError,
  resetError,
  setReportData,
  setRootEntity,
  setSelectedEntityFields,
  setFilters,
  setReportName,
  setReportDescription
} = slice.actions;

export function fetchReportableEntities() {
  return async (dispatch) => {
    axios
      .get('/api/reports/builder/entity')
      .then((res) => {
        dispatch(slice.actions.setReportableEntities(res.data.data));
      })
      .catch((error) => {
        dispatch(slice.actions.setError(error));
      });
  };
}

export function startBuilderWith(id) {

  return async (dispatch) => {
    axios
      .get(`/api/reports/${id}`)
      .then((res) => {
        const reportDefinition = res.data.data;
        dispatch(slice.actions.setRootEntity(reportDefinition.entity));
        dispatch(slice.actions.setReportId(reportDefinition.id));
        dispatch(slice.actions.setSelectedEntityFields(reportDefinition.fields));
        dispatch(slice.actions.setFilters(reportDefinition.filters));
        dispatch(slice.actions.setReportName(reportDefinition.name));
        dispatch(slice.actions.setReportDescription(reportDefinition.description));
      })
      .catch((error) => {
        dispatch(slice.actions.setError(error));
      });
  };
}

export function fetchReportData({
  entity,
  fields,
  filters,
  aggregations,
  pagination
}) {
  return async (dispatch) => {
    const requestParams = {
      entity: entity,
      fields: fields,
      filters: filters,
      aggregations: aggregations,
      pagination: pagination
    };

    axios
      .get(`/api/reports/builder`, {
        params: {
          reportParams: requestParams
        }
      })
      .then((res) => {
        const dataStructure = {
          fieldNames: res.data.data.fields.map((f) => f.name),
          fieldLabels: res.data.data.fields.map((f) => f.label),
          data: res.data.data.result,
          total: res.data.data.totalRows
        };
        dispatch(slice.actions.setReportData(dataStructure));
      })
      .catch((error) => {
        dispatch(slice.actions.setError(error));
      });
  };
}
