import axios, { api2 } from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  detail: '',
  schedList: [],
  currentSchedule: undefined,
  total: 0
};

const slice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSchedList(state, action) {
      state.schedList = action.payload;
      state.isLoading = false;
    },
    setCurrentSchedule(state, action) {
      state.currentSchedule = action.payload;
      state.isLoading = false;
    },
    setTotal(state, action) {
      state.total = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSchedList, setCurrentSchedule } = slice.actions;

export function getSchedList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await api2.get(`/api/v1/schedules`);
      if (result.data?.data) {
        dispatch(slice.actions.setSchedList(result.data.data));
        dispatch(slice.actions.setTotal(result.data.total));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Actualizar !
export function getScheduleById(tagId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await axios.get(`/api/job/${tagId}`);
      dispatch(slice.actions.setCurrentSchedule(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveSchedule(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.post(`/api/job`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
      return resp;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSchedule(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.patch(`/api/job/${body.id}`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
      return resp;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteScheduleById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/job/${id}`);
      dispatch(getSchedList());
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// Actualizar !
